<template>
  <order />
</template>

<script>
// @ is an alias to /src
import order from "@/components/Orders-Vendor/c-orders-list.vue";

export default {
  name: "Orders",
  components: {
    order,
  },
};
</script>

<template>
  <div v-if="roles">
    <!-- <v-btn @click="dialogOrderDetail = !dialogOrderDetail">TEST</v-btn> -->

    <v-col v-if="informText != ''">
      <v-alert type="error" dismissible>{{ informText }}</v-alert>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col cols="8">
          <v-btn
            text
            small
            @click="
              filterData.status = 'All';
              search();
            "
          >
            <v-tab>
              <v-badge
                color="cyan"
                :content="totalItems.toString()"
                style="font-size: 10px"
              >
                <b v-if="filterData.status == 'All'" style="color: #7cb342"
                  >All</b
                >
                <b v-else>All</b>
              </v-badge>
            </v-tab>
          </v-btn>
          <v-btn
            text
            small
            @click="
              filterData.status = 'New';
              search();
            "
          >
            <v-tab>
              <v-badge
                color="orange"
                :content="newItems.toString()"
                style="font-size: 10px"
              >
                <b v-if="filterData.status == 'New'" style="color: #7cb342"
                  >New</b
                >
                <b v-else>New</b>
              </v-badge>
            </v-tab>
          </v-btn>
          <v-btn
            text
            small
            @click="
              filterData.status = 'Unpaid';
              search();
            "
          >
            <v-tab>
              <v-badge
                color="orange"
                :content="unpaidItems.toString()"
                style="font-size: 10px"
              >
                <b v-if="filterData.status == 'Unpaid'" style="color: #7cb342"
                  >Unpaid</b
                >
                <b v-else>Unpaid</b>
              </v-badge>
            </v-tab>
          </v-btn>
          <v-btn
            text
            small
            @click="
              filterData.status = 'Paid';
              search();
            "
          >
            <v-tab>
              <v-badge
                color="info"
                :content="paidItems.toString()"
                style="font-size: 10px"
              >
                <b v-if="filterData.status == 'Paid'" style="color: #7cb342"
                  >Paid</b
                >
                <b v-else>Paid</b>
              </v-badge>
            </v-tab>
          </v-btn>
          <v-btn
            text
            small
            @click="
              filterData.status = 'In Production';
              search();
            "
          >
            <v-tab>
              <v-badge
                color="info"
                :content="inProductionItems.toString()"
                style="font-size: 10px"
              >
                <b
                  v-if="filterData.status == 'In Production'"
                  style="color: #7cb342"
                  >In Production</b
                >
                <b v-else>In Production</b>
              </v-badge>
            </v-tab>
          </v-btn>
          <v-btn
            text
            small
            @click="
              filterData.status = 'Shipped';
              search();
            "
          >
            <v-tab>
              <v-badge
                color="info"
                :content="shippedItems.toString()"
                style="font-size: 10px"
              >
                <b v-if="filterData.status == 'Shipped'" style="color: #7cb342"
                  >Shipped</b
                >
                <b v-else>Shipped</b>
              </v-badge>
            </v-tab>
          </v-btn>
          <v-btn
            text
            small
            @click="
              filterData.status = 'Delivered';
              search();
            "
          >
            <v-tab>
              <v-badge
                color="success"
                :content="deliveredItems.toString()"
                style="font-size: 10px"
              >
                <b
                  v-if="filterData.status == 'Delivered'"
                  style="color: #7cb342"
                  >Delivered</b
                >
                <b v-else>Delivered</b>
              </v-badge>
            </v-tab>
          </v-btn>
          <v-btn
            text
            small
            @click="
              filterData.status = 'Canceled';
              search();
            "
          >
            <v-tab>
              <v-badge
                color="red"
                :content="canceledItems.toString()"
                style="font-size: 10px"
              >
                <b v-if="filterData.status == 'Canceled'" style="color: #7cb342"
                  >Canceled</b
                >
                <b v-else>Canceled</b>
              </v-badge>
            </v-tab>
          </v-btn>
        </v-col>

        <v-col cols="4" style="text-align: right">
          <v-btn
            small
            v-model="fab"
            color="black"
            dark
            v-if="selected.length > 0"
            @click="clearSelected"
          >
            Clear ({{ selected.length }})</v-btn
          >
          &nbsp;
          <v-btn small @click.stop="drawer = !drawer" outlined color="indigo"
            >Filter Data</v-btn
          >
          &nbsp;
          <template>
            <v-speed-dial
              v-model="fab"
              :direction="direction"
              :open-on-hover="hover"
              :transition="transition"
              style="width: 15px; display: inline"
            >
              <template v-slot:activator>
                <v-btn small v-model="fab" color="yellow accent-1">
                  Bulk Actions
                  <v-icon v-if="fab">mdi-close</v-icon>
                  <v-icon v-else>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <template>
                <v-btn
                  small
                  color="success"
                  v-if="filterData.status != '' && roles.download_items == 1"
                  @click="dialogDownloadOrder = !dialogDownloadOrder"
                  >Download</v-btn
                >
                <v-btn small disabled v-else> Download </v-btn>
              </template>
              <template>
                <v-btn
                  small
                  color="teal"
                  class="white--text"
                  v-if="
                    selected.length > 0 &&
                    filterData.status === 'Unpaid' &&
                    roles.change_item_status == 1
                  "
                  @click="dialogMarkAsPaid = !dialogMarkAsPaid"
                >
                  Mark As Paid
                </v-btn>
                <v-btn small color="teal" class="white--text" disabled v-else>
                  Mark As Paid
                </v-btn>
              </template>
              <template>
                <v-btn
                  small
                  color="blue-grey"
                  class="white--text"
                  v-if="
                    selected.length > 0 &&
                    filterData.status === 'Paid' &&
                    roles.change_item_status == 1
                  "
                  @click="changeStatusOrder('In Production')"
                >
                  Start Production
                </v-btn>
                <v-btn
                  small
                  color="blue-grey"
                  class="white--text"
                  v-else
                  disabled
                >
                  Start Production
                </v-btn>
              </template>
              <template>
                <v-btn
                  small
                  color="red"
                  dark
                  class="white--text"
                  v-if="selected.length > 0 && roles.change_item_status == 1"
                  @click="changeStatusOrder('Canceled')"
                >
                  Cancel Order
                </v-btn>
                <v-btn small class="white--text" v-else disabled>
                  Cancel Order
                </v-btn>
              </template>
            </v-speed-dial>
          </template>
          &nbsp;
          <v-btn
            small
            @click="dialogUpload = !dialogUpload"
            color="green accent-1"
            v-if="roles.upload_base_cost == 1"
          >
            Upload Tracking & Cost</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Unpaid Amount
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <div
                    style="
                      background-color: #65c790;
                      border-radius: 5px;
                      padding: 10px 25px 10px 10px;
                      color: white;
                      margin-right: 5px;
                    "
                    v-for="(value, text, indexA) in listUnpaidAmount"
                    :key="indexA"
                  >
                    <h4>{{ text }}</h4>
                    <h2>${{ parseFloat(value).toFixed(2) }}</h2>
                  </div>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-col>

    <v-data-table
      v-model="selected"
      :items="ordersData"
      :headers="headers"
      :server-items-length="marchingOrders"
      :options.sync="pagination"
      :loading="loading"
      class="elevation-1"
      item-key="_id"
      show-select
      :footer-props="{
        'items-per-page-options': [
          10, 20, 50, 100, 200, 500, 800, 1000, 1500, 2000,
        ],
      }"
    >
      <template v-slot:item.vendor_order_number="{ item }">
        <div>
          <a
            v-if="roles.read_item == 1"
            @click="
              dialogOrderDetail = !dialogOrderDetail;
              detailItem = item;
            "
            >{{ item.vendor_order_number }}</a
          >
          <div v-else>{{ item.vendor_order_number }}</div>
        </div>
        <div>{{ item.item_id }}</div>
      </template>

      <template v-slot:item.item_name="{ item }">
        {{ item.quantity }} x {{ item.item_name }}
      </template>

      <template v-slot:item.paid_at="{ item }">
        <div>{{ item.paid_at }}</div>
        <div v-if="item.payment_proof">
          (<a
            :href="item.payment_proof"
            target="_blank"
            style="text-decoration: none"
            >Proof</a
          >)
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <td>
          <v-menu
            :close-on-content-click="false"
            :nudge-width="0"
            offset-x
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <template>
                <a v-bind="attrs" v-on="on" v-if="item.status == 'Canceled'">{{
                  item.status
                }}</a>
                <a
                  v-bind="attrs"
                  v-on="on"
                  v-else
                  v-bind:style="{ color: item.sttColor }"
                  >{{ item.status }} ({{ item.status_time }})</a
                >
              </template>
            </template>

            <v-card class="mx-auto" max-width="344" outlined>
              <v-list-item five-line class="border">
                <v-list-item-content style="text-align: left">
                  <div><b>Created At:</b> {{ item.created_at }}</div>
                  <div><b>Paid At:</b> {{ item.paid_at }}</div>
                  <div><b>Processed At:</b> {{ item.processed_at }}</div>
                  <div><b>Shipped At:</b> {{ item.shipped_at }}</div>
                  <div><b>Delivered At:</b> {{ item.delivered_at }}</div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-menu>
        </td>
      </template>

      <template v-slot:item.has_issue="{ item }">
        <template v-if="item.has_issue == 1">
          <v-icon color="deep-orange">mdi-alert</v-icon>
        </template>

        <template v-if="item.base_cost"> ${{ item.base_cost }} </template>
      </template>

      <template v-slot:item.tracking_number="{ item }">
        <div>{{ item.tracking_number }}</div>
        <div>{{ item.tracking_carrier }}</div>
      </template>

      <template v-slot:item.shipped_at="{ item }">
        <div v-if="item.target_ship_date">
          <b>Target: </b>{{ item.target_ship_date }}
        </div>
        <div v-if="item.shipped_at">
          <span
            v-if="item.shipped_at > item.target_ship_date"
            style="color: red"
            ><b>Actual: </b>{{ item.shipped_at }}</span
          >
          <span v-else style="color: green"
            ><b>Actual: </b>{{ item.shipped_at }}</span
          >
        </div>
      </template>

      <template v-slot:item.delivered_at="{ item }">
        <div v-if="item.target_delivery_date">
          <b>Target: </b>{{ item.target_delivery_date }}
        </div>
        <div v-if="item.delivered_at">
          <span
            v-if="item.delivered_at > item.target_delivery_date"
            style="color: red"
            ><b>Actual: </b>{{ item.delivered_at }}</span
          >
          <span v-else style="color: green"
            ><b>Actual: </b>{{ item.delivered_at }}</span
          >
        </div>
      </template>
    </v-data-table>

    <v-navigation-drawer v-model="drawer" absolute right temporary>
      <v-list nav dense class="menu-scroll">
        <v-list-item>
          <v-text-field
            label="Order ID"
            outlined
            v-model="filterData.vendor_order_number"
            @keyup.enter="search"
            dense
          ></v-text-field>
        </v-list-item>

        <v-list-item>
          <v-text-field
            label="Item ID"
            outlined
            v-model="filterData.item_id"
            @keyup.enter="search"
            dense
          ></v-text-field>
        </v-list-item>

        <v-list-item>
          <v-select
            :items="listVendors"
            :item-text="(obj) => obj.vendor_code"
            :item-value="(obj) => obj.vendor_code"
            label="Select Vendor"
            dense
            multiple
            outlined
            v-model="filterData.vendor_code"
          ></v-select>
        </v-list-item>

        <v-list-item>
          <v-select
            :items="listStore"
            :item-text="(obj) => obj.code"
            :item-value="(obj) => obj.code"
            label="Select Store"
            dense
            outlined
            v-model="filterData.siteCodes"
            multiple
          ></v-select>
        </v-list-item>

        <v-list-item>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            persistent
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Created Date"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filterData.dates"
              range
              scrollable
            ></v-date-picker>
            <!-- <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker> -->
          </v-menu>
        </v-list-item>

        <v-col cols="12">
          <!-- <v-btn @click.stop="drawer = false"  color="warning" fab x-small dark style="float:left">
            <v-icon class="fa fa-arrow-left"></v-icon>
          </v-btn>&nbsp; -->
          <v-btn
            @click="search"
            small
            outlined
            color="indigo"
            style="float: left"
            >Apply</v-btn
          >
          <v-btn
            @click="reset"
            small
            outlined
            color="indigo"
            style="float: right"
            >Reset</v-btn
          >
        </v-col>
      </v-list>
    </v-navigation-drawer>

    <BaseDialog :active.sync="dialogOrderDetail" dialog_name="Order Detail">
      <OrderDetail :orderItem="detailItem" :listStatus="listStt" />
    </BaseDialog>

    <BaseDialog :active.sync="dialogDownloadOrder" dialog_name="Download Order">
      <DownloadOrder :selectedItem="selected" :filterData="filterData" />
    </BaseDialog>

    <BaseDialog
      :active.sync="dialogUpload"
      dialog_name="Upload Tracking & Cost"
    >
      <UploadData />
    </BaseDialog>

    <BaseDialog :active.sync="dialogMarkAsPaid" dialog_name="Mark As Paid">
      <MarkAsPaid :selectedItem="selected" />
    </BaseDialog>
  </div>
</template>

<script>
import axios from "axios";
import BaseDialog from "../Common/c-base-dialog.vue";
import OrderDetail from "./c-dialog-order-detail.vue";
import DownloadOrder from "./c-dialog-download.vue";
import UploadData from "./c-dialog-upload-data.vue";
import MarkAsPaid from "./c-dialog-mark-as-paid.vue";
import moment from "moment-timezone";

export default {
  name: "OrderListComponent",

  components: {
    BaseDialog,
    OrderDetail,
    DownloadOrder,
    UploadData,
    MarkAsPaid,
  },

  data: () => ({
    ordersData: [],
    listVendors: [],
    listStt: [],
    listUnpaidAmount: [],
    listStore: [],

    totalItems: 0,
    newItems: 0,
    unpaidItems: 0,
    paidItems: 0,
    inProductionItems: 0,
    shippedItems: 0,
    deliveredItems: 0,
    canceledItems: 0,
    marchingOrders: 0,

    direction: "bottom",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    transition: "slide-y-reverse-transition",

    dialogOrderDetail: false,
    dialogDownloadOrder: false,
    dialogUpload: false,
    dialogMarkAsPaid: false,
    informText: "",

    headers: [
      { text: "Order ID & Item ID", value: "vendor_order_number" },
      { text: "Items", value: "item_name", width: "15%" },
      { text: "Basecost", value: "has_issue" },
      { text: "Status", value: "status" },
      { text: "Tracking Info", value: "tracking_number" },
      { text: "Paid at", value: "paid_at" },
      { text: "Shipped at", value: "shipped_at" },
      { text: "Delivered at", value: "delivered_at" },
      { text: "Vendor", value: "vendor_code" },
    ],
    selected: [],
    loading: false,
    pagination: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: false,
      groupDesc: false,
      multiSort: false,
      mustSort: false,
    },

    drawer: false,
    filterData: {
      vendor_order_number: "",
      item_id: "",
      vendor_code: [],
      dates: [],
      status: "All",
      siteCodes: [],
    },
    menu2: false,

    detailItem: null,
  }),

  computed: {
    roles() {
      return this.$store.state.gbaUserPermissions.chinese_suppliers;
    },

    dateRangeText() {
      return this.filterData.dates.join(" ~ ");
    },
  },

  created() {
    this.$root.$refs.ordersList = this;
  },

  watch: {
    pagination: {
      handler() {
        let queryParams = this.getParams();

        this.$router
          .push({ path: "supplier-orders", query: queryParams })
          .catch((err) => {
            var error = err.toString();
            if (!error.includes("redundant navigation")) {
              throw err;
            }
          });
        //console.log("pagination");

        this.loadData();
      },
      deep: true,
    },
  },

  methods: {
    turnOffDialog(data) {
      //console.log("data turnOffDialog: ", data);
      this.dialogOrderDetail = false;
      this.dialogDownloadOrder = false;
      this.dialogUpload = false;
      this.dialogMarkAsPaid = false;

      if (typeof data === "string") {
        this.informText = data;
      } else {
        this.informText = "";
      }

      this.loadData();
    },

    clearSelected() {
      this.selected = [];
      this.$forceUpdate();
    },

    getParams() {
      let queryParams = {
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        sortBy: this.pagination.sortBy[0],
        sortDesc: this.pagination.sortDesc[0],
      };

      //console.log("filterData: ", this.filterData);

      if (this.filterData.vendor_order_number != "") {
        queryParams["orderNumber"] = this.filterData.vendor_order_number;
      }

      if (this.filterData.item_id != "") {
        queryParams["itemID"] = this.filterData.item_id;
      }

      if (this.filterData.vendor_code.length > 0) {
        queryParams["vendorCode"] = this.filterData.vendor_code.join(",");
      }

      if (this.filterData.status != "") {
        queryParams["status"] = this.filterData.status;
      }

      if (this.filterData.siteCodes.length > 0) {
        queryParams["siteCodes"] = this.filterData.siteCodes.join(",");
      }

      if (this.filterData.dates.length > 0) {
        if (this.filterData.dates[0] > this.filterData.dates[1]) {
          queryParams["createFrom"] = this.filterData.dates[1];
          queryParams["createTo"] = this.filterData.dates[0];
        } else {
          queryParams["createFrom"] = this.filterData.dates[0];
          queryParams["createTo"] = this.filterData.dates[1];
        }
      }

      return queryParams;
    },

    async loadData() {
      this.loading = true;

      var timeZone = localStorage.getItem("timeZone");
      //console.log("timeZone: ", timeZone);

      await this.$root.$refs.app.checkToken();

      var query = this.getParams();

      //console.log("query: ", query);
      if (this.roles.list_items == 1) {
        var response = await axios.get(window.domain + "fulfillment", {
          params: query,
        });

        if (response.data.error_message == "") {
          this.ordersData = response.data.data.items;
          this.listVendors = response.data.data.vendors;
          this.listStt = response.data.data.statuses;
          this.listUnpaidAmount = response.data.data.unpaidAmounts;
          this.listStore = response.data.data.sites;
          this.totalItems = response.data.data.totalItems || "0";
          this.marchingOrders = response.data.data.marchingOrders || 0;

          this.newItems = response.data.data.newItems || "0";
          this.unpaidItems = response.data.data.unpaidItems || "0";
          this.paidItems = response.data.data.paidItems || "0";
          this.inProductionItems = response.data.data.inProductionItems || "0";
          this.shippedItems = response.data.data.shippedItems || "0";
          this.deliveredItems = response.data.data.deliveredItems || "0";
          this.canceledItems = response.data.data.canceledItems || "0";

          for (var a of this.ordersData) {
            //a["status_time"] = "";
            switch (a.status) {
              case "New":
                a["sttColor"] = "orange";
                break;
              case "Unpaid":
                a["sttColor"] = "orange";
                break;
              case "Paid":
                a["sttColor"] = "#3796F3";
                break;
              case "In Production":
                a["sttColor"] = "#3796F3";
                break;
              case "Shipped":
                a["sttColor"] = "#3796F3";
                break;
              case "Delivered":
                a["sttColor"] = "#4CAF50";
                break;
              case "Canceled":
                a["sttColor"] = "red";
                break;
              default:
            }

            switch (a.status) {
              case "New":
                a["status_time"] = moment(a.created_at)
                  //.tz(this.$store.state.timeZoneSelected)
                  .tz(timeZone)
                  .fromNow();
                break;
              case "Unpaid":
                a["status_time"] = moment(a.created_at)
                  //.tz(this.$store.state.timeZoneSelected)
                  .tz(timeZone)
                  .fromNow();
                break;
              case "Paid":
                a["status_time"] = moment(a.paid_at)
                  //.tz(this.$store.state.timeZoneSelected)
                  .tz(timeZone)
                  .fromNow();
                break;
              case "In Production":
                a["status_time"] = moment(a.processed_at)
                  //.tz(this.$store.state.timeZoneSelected)
                  .tz(timeZone)
                  .fromNow();
                break;
              case "Shipped":
                a["status_time"] = moment(a.shipped_at)
                  //.tz(this.$store.state.timeZoneSelected)
                  .tz(timeZone)
                  .fromNow();
                break;
              case "Delivered":
                a["status_time"] = moment(a.delivered_at)
                  //.tz(this.$store.state.timeZoneSelected)
                  .tz(timeZone)
                  .fromNow();
                break;
              default:
                a["status_item"] = "";
            }

            if (a.created_at) {
              a.created_at = moment(a.created_at)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");
            }

            if (a.paid_at) {
              a.paid_at = moment(a.paid_at)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");
            }

            if (a.processed_at) {
              a.processed_at = moment(a.processed_at)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");
            }

            if (a.shipped_at) {
              a.shipped_at = moment(a.shipped_at)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");
            }

            if (a.delivered_at) {
              a.delivered_at = moment(a.delivered_at)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");
            }

            if (a.target_delivery_date) {
              a.target_delivery_date = moment(a.target_delivery_date)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");
            }

            if (a.target_ship_date) {
              a.target_ship_date = moment(a.target_ship_date)
                //.tz(this.$store.state.timeZoneSelected)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:MM:SS");
            }
          }
        } else {
          this.informText = response.data.error_message;
        }
      }

      this.loading = false;
    },

    search() {
      let queryParams = this.getParams();
      queryParams.page = 1;
      this.pagination.page = 1;
      this.selected = [];

      this.$router
        .push({ path: "supplier-orders", query: queryParams })
        .catch((err) => {
          var str = err.toString();
          if (str.includes("current location")) {
            err = "";
          }
        });
      this.loadData();
    },

    reset() {
      this.filterData = {
        vendor_order_number: "",
        item_id: "",
        vendor_code: "",
        dates: [],
        status: "",
        siteCodes: [],
      };
    },

    async changeStatusOrder(status) {
      this.informText = "";

      var r = confirm(
        "Are you sure to change the selected item(s) to " + status + "?"
      );

      if (r) {
        await this.$root.$refs.app.checkToken();

        var item = {
          new_status: status,
          item_ids: [],
        };

        for (var a of this.selected) {
          item.item_ids.push(a._id);
        }

        // if (status == "Paid") {
        //   let person = prompt("Please enter payment proof image link:", "");
        //   item["payment_proof"] = person;
        // }

        await axios
          .post(window.domain + "fulfillment/change-status", item)
          .then((response) => {
            if (response.data.error_message != "") {
              this.informText = response.data.error_message;
            }
          })
          .catch((error) => console.log("Error: ", error));

        this.loadData();
      }
    },
  },

  async mounted() {
    if (this.$store.state.gbaUser.indexOf("partner") > -1) {
      window.location.href = "error";
    }
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panels {
  z-index: 0;
}

/*
.v-speed-dial {
  z-index: 0;
}
*/
</style>
<template>
  <div>
    <v-alert type="info">
      <b>Total Base Cost: ${{ parseFloat(totalBaseCost).toFixed(2) }}</b>
    </v-alert>
    <v-col cols="12">
      <v-row>
        <div>Selected Order: {{ selectedItem.length }} item(s)</div>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="success"
          @click="saveData"
          v-if="imageDesign.length > 0 && url_link != ''"
          >Submit</v-btn
        >
        <v-btn
          small
          color="success"
          @click="saveData"
          v-else-if="imageDesign.length == 0 && url_link == ''"
          >Submit</v-btn
        >
        <v-btn
          small
          disabled
          v-else-if="imageDesign.length > 0 && url_link == ''"
          >Submit</v-btn
        >
        &nbsp;
        <v-btn small class="white--text" color="warning" @click="cancelData">
          Cancel <v-icon right dark>mdi-window-close</v-icon>
        </v-btn>
      </v-row>
    </v-col>
    <v-col cols="12">
      <input
        type="file"
        @change="onFileDesignChange"
        ref="inputDesign"
        style="display: none"
      />

      <v-col cols="12">
        <v-row>
          <v-btn @click="$refs.inputDesign.click()" color="grey" small dark
            >Select Proof Img</v-btn
          >
          &nbsp;
          <v-btn
            color="purple"
            dark
            @click="uploadImagePreview"
            small
            v-if="imageDesign.length > 0 && url_link == '' && !loading"
            >Upload</v-btn
          >
          <template v-else-if="url_link != ''"
            ><b>Proof Link: </b>{{ url_link }}</template
          >
          <template v-else-if="loading">
            <v-btn disabled small>Upload</v-btn>
          </template>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="imageDesign.length > 0">
        <div>Preview Image</div>
        <img v-bind:src="imageDesign[0].image" style="width: 100%" /><br />
      </v-col>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";
import Amplify from "aws-amplify";
//import Multiselect from 'vue-multiselect'

Amplify.configure({
  Auth: {
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_8vBdk5PAd",
    userPoolWebClientId: "51e572vhkn38k7tuvsdst697b1",
    mandatorySignIn: true,
  },
});

const API_ENDPOINT =
  "https://fthzm2m3h3.execute-api.ap-southeast-1.amazonaws.com/tools/upload-image-to-s3";
const MAX_IMAGE_SIZE = 6300000;

export default {
  name: "DialogMarkAsPaid",

  props: {
    selectedItem: Array,
  },

  data: () => ({
    imageDesign: [],
    imageFormat: "",
    url_link: "",
    loading: false,
    totalBaseCost: 0,
  }),

  methods: {
    cancelData() {
      this.$root.$refs.ordersList.turnOffDialog();
    },

    onFileDesignChange(e) {
      this.imageDesign = [];
      let files = e.target.files; // || e.dataTransfer.files
      if (!files.length) return;

      this.createImage(files[0]);
    },

    cancelImageDesign() {
      this.imageDesign = [];
    },

    createImage(file) {
      this.errorMess = "";
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target.result.includes("data:image/jpeg")) {
          this.imageFormat = "image/jpeg";
        } else if (e.target.result.includes("data:image/png")) {
          this.imageFormat = "image/png";
        } else if (e.target.result.includes("data:image/bmp")) {
          this.imageFormat = "image/bmp";
        } else {
          return (this.errorMess = "Wrong file type - JPG/PNG/BMP only.");
        }

        console.log("e.target.result.length: ", e.target.result.length);
        if (e.target.result.length > MAX_IMAGE_SIZE) {
          return (this.errorMess = "Image is loo large - 4.7MB maximum");
        }

        var item = {
          image: e.target.result,
          format: this.imageFormat,
        };
        this.imageDesign.push(item);
      };
      reader.readAsDataURL(file);
    },

    async uploadImagePreview() {
      this.loading = true;
      var base64EncodedStr = this.imageDesign[0].image.split(",")[1];
      await this.$root.$refs.app.checkToken();
      let response = await axios({
        method: "POST",
        url: API_ENDPOINT,
        data: {
          // image_base64: base64EncodedStr,
          // image_folder: "product-design",
          // image_format: this.imageDesign[0].format,
          // product_id: this.$route.params.id,
          // designer_username: this.designer_id,
          // vendor_id: this.vendorID,
          // product_code: this.product_code,
          // variant_ids: this.listVariants,

          image_base64: base64EncodedStr,
        },
      }).catch((error) => {
        console.log("Err: ", error);
      });
      //console.log("uploadImagePreview response: ", response);
      if (response.data.error_message == "") {
        this.loading = false;
        this.url_link = response.data.data.image_url;
      } else {
        this.errorMess = response.data.error_message;
      }
    },

    async saveData() {
      await this.$root.$refs.app.checkToken();

      var item = {
        new_status: "Paid",
        item_ids: [],
        payment_proof: "",
      };

      for (var a of this.selectedItem) {
        item.item_ids.push(a._id);
      }

      item.payment_proof = this.url_link;

      await axios
        .post(window.domain + "fulfillment/change-status", item)
        .then((response) => {
          if (response.data.error_message != "") {
            this.informText = response.data.error_message;
          } else {
            this.cancelData();
          }
        })
        .catch((error) => console.log("Error: ", error));
    },
  },

  mounted() {
    for (const a of this.selectedItem) {
      this.totalBaseCost += a.base_cost;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>